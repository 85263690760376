<template>
  <div>
    <sidebar
      :day="day"
      :is-sidebar-active.sync="isSidebarActive"
      :data="sidebarData"
      @addWorkPeriod="addWorkPeriod"
      @updateWorkPeriod="updateWorkPeriod"
    />
    <b-modal
      :visible="isModalActive"
      :title="$t('Change Period')"
      ok-title="Accept"
      size="lg"
      @change="val => $emit('update:is-modal-active', val)"
    >
      <div class="m-2">
        <b-tabs>
          <b-tab
            :active="day === 'Monday' ? true : false"
            :title="$t('Monday')"
            @click="chanageDay('Monday')"
          />
          <b-tab
            :active="day === 'Tuesday' ? true : false"
            :title="$t('Tuesday')"
            @click="chanageDay('Tuesday')"
          />
          <b-tab
            :active="day === 'Wednesday' ? true : false"
            :title="$t('Wednesday')"
            @click="chanageDay('Wednesday')"
          />
          <b-tab
            :active="day === 'Thursday' ? true : false"
            :title="$t('Thursday')"
            @click="chanageDay('Thursday')"
          />
          <b-tab
            :active="day === 'Friday' ? true : false"
            :title="$t('Friday')"
            @click="chanageDay('Friday')"
          />
          <b-tab
            :active="day === 'Saturday' ? true : false"
            :title="$t('Saturday')"
            @click="chanageDay('Saturday')"
          />
          <b-tab
            :active="day === 'Sunday' ? true : false"
            :title="$t('Sunday')"
            @click="chanageDay('Sunday')"
          />
        </b-tabs>
      </div>
      <div class="m-2">
        <b-row>
          <b-col>
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector v-select-custom"
              @input="pageSize"
            />
            <label for="">{{ $t('entries') }}</label>
            <span
              class="ml-1 text-muted"
            >{{ currentPage * perPage - (perPage - 1) }} -
              {{
                queriedItems - currentPage * perPage > 0
                  ? currentPage * perPage
                  : queriedItems
              }}
              {{ $t('of') }} {{ queriedItems }}</span>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="b-col-custom"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')+'...'"
                  @input="typing($event)"
                /> -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex"
                @click="sidebarAddNew()"
              >
                <feather-icon icon="PlusIcon" />
                <!-- <span class="align-middle">Add Users</span> -->
              </b-button>
              <!-- <b-form-file
                  @change="importFileJson"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".json"
                /> -->
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        striped
        responsive
        class="position-relative"
        :items="respData"
        :fields="tableColumns"
        primary-key="_id"
        show-empty
        :empty-text="$t('No matching records found')"
        style="height: 60vh;"
      >
        <template #cell(openTime)="data">
          <p class="align-text-bottom line-height-1">
            {{ timeFomat(data.item.openTime.hour) }} :
            {{ timeFomat(data.item.openTime.minute) }}
          </p>
        </template>
        <template #cell(closeTime)="data">
          <p class="align-text-bottom line-height-1">
            {{ timeFomat(data.item.closeTime.hour) }} :
            {{ timeFomat(data.item.closeTime.minute) }}
          </p>
        </template>
        <!-- Column: Action -->
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="sidebarEdit(data.item, data.item.index)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteData(data.item.index)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <template #modal-footer>
        <div class="w-100">
          <p class="float-left mb-0" />
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span
                  class="text-muted"
                >{{ currentPage * perPage - (perPage - 1) }} -
                  {{
                    queriedItems - currentPage * perPage > 0
                      ? currentPage * perPage
                      : queriedItems
                  }}
                  {{ $t('of') }} {{ queriedItems }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="queriedItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="nextPage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BFormCheckbox,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
  // BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import perPageOptions from '@/perPageOptions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import Sidebar from './DataSidebar.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTabs,
    BTab,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    Sidebar,
    // BOverlay,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      dataLoading: false,
      currentPage: 1,
      day: 'Monday',
      name: '',
      image: null,
      workPeriod: [],
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 50,
      isSidebarActive: false,
      sidebarData: {},
      perPageOptions,
      required,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'openTime', label: this.$t('openTime'), sortable: false },
        { key: 'closeTime', label: this.$t('closeTime'), sortable: false },
        { key: 'limitMember', label: this.$t('Limit Member'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respData() {
      return this.workPeriod
        .map((item, index) => ({
          index,
          _id: item._id,
          day: item.day,
          openTime: item.openTime,
          closeTime: item.closeTime,
          limitMember: item.limitMember,
        }))
        .filter(e => e.day.toString() === this.day.toString())
    },
    queriedItems() {
      return this.workPeriod.filter(
        e => e.day.toString() === this.day.toString(),
      ).length
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id, image, name, workPeriod,
          } = this.data
          this.dataId = _id
          this.name = name
          this.image = image
          this.workPeriod = workPeriod
        }
      }
    },
    workPeriod: {
      handler(val) {
        console.log('workPeriod ', val)
        this.update()
      },
      deep: true,
    },
  },
  methods: {
    update() {
      store
        .dispatch(`${this.storeModuleName}/update`, {
          id: this.dataId,
          image: this.image,
          name: this.name,
          workPeriod: this.workPeriod.map(e => ({
            closeTime: e.closeTime,
            day: e.day,
            limitMember: e.limitMember,
            openTime: e.openTime,
          })),
        })
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    addWorkPeriod(data) {
      this.workPeriod.push({ ...data, index: this.workPeriod.length })
    },
    updateWorkPeriod(data) {
      if (data.index > -1) {
        // this.workPeriod[data.index] = data
        Object.assign(this.workPeriod[data.index], data)
        this.update()
      }
    },
    sidebarAddNew() {
      this.sidebarData = {}
      this.isSidebarActive = true
    },
    sidebarEdit(item) {
      this.sidebarData = item
      this.isSidebarActive = true
    },
    deleteData(index) {
      this.workPeriod.splice(index, 1)
    },
    timeFomat(n) {
      if (n !== null) {
        if (n.toString().length === 1) {
          return `0${n.toString()}`
        }
      }
      return n
    },
    chanageDay(value) {
      this.day = value
    },
    initValues() {
      this.dataId = null
      this.workPeriod = []
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
    },
    nextPage(page) {
      this.currentPage = page
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
