<template>
  <div>
    <b-modal :visible="isModalActive" :title="$t('Adjust')" ok-title="Accept" size="lg" no-close-on-backdrop
      @change="val => $emit('update:is-modal-active', val)">
      <b-overlay :show="show" rounded="sm">
        <div class="m-2">
          <v-select @input="selectProfile($event)" v-model="profiles" chips multiple size="sm" label="name"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="respDataProfile"
            :reduce="respDataProfile => respDataProfile._id" :clearable="true" class="select-size-sm" />
        </div>
      </b-overlay>

      <template #modal-footer {hide}>
        <div class="w-100">
          <div class="float-right">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" size="sm" class="mr-2" @click="save">
              {{ $t('Save') }}
              <feather-icon icon="ShoppingBagIcon" size="12" />
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" size="sm" class="mr-2"
              @click="$emit('update:is-modal-active', false)">
              {{ $t('Cancel') }}
              <feather-icon icon="ShoppingBagIcon" size="12" />
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import perPageOptions from '@/perPageOptions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // BOverlay,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    storeModuleNameProfile: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => { },
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      profiles: [],
      perPageOptions,
      required,
    }
  },
  computed: {
    respDataProfile() {
      const data = [{
        _id: 0,
        name: this.$t('All'),
      }]

      if (store.state[this.storeModuleNameProfile].respData != null) {
        store.state[this.storeModuleNameProfile].respData.data.map(e => {
          data.push(({
            _id: e._id,
            name: e.firstName,
          }))
        })
      }

      return data
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const { _id } = this.data
          this.dataId = _id
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.profiles = []
    },
    selectProfile(e) {
      if (e[e.length - 1] == 0) {
        this.respDataProfile.map(e => {
          if (!this.profiles.includes(e._id)) {
            this.profiles.push(e._id)
          }
        })

        this.profiles = this.profiles.filter((item) => item !== 0)
      }
    },
    save() {
      this.$emit('update:show', true)

      store
        .dispatch(`${this.storeModuleName}/saveProfile`, {
          id: this.dataId,
          profiles: this.profiles,
        })
        .then(result => {
          console.log('fetch Success : ', result)
          this.initValues()
          this.$emit('update:show', false)
          this.$emit('update:is-modal-active', false)
          this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$emit('update:show', false)

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
