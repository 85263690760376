<template>
  <b-modal
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    :title="Object.entries(data).length === 0 ? $t('Add New') : $t('Update')"
    ok-title="Accept"
    size="lg"
    @change="val => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ }">
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('Limit Member')"
                label-for="register-limitMember"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Limit Member"
                  vid="limitMember"
                  rules=""
                >
                  <b-form-input
                    id="register-limitMember"
                    v-model="limitMember"
                    name="register-limitMember"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Limit Member')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <span>{{ $t('openTime') }}</span>
            </b-col>
            <b-col cols="6">
              <!-- firstName -->
              <b-form-group
                :label="$t('Hour')"
                label-for="register-openTimeHour"
              >
                <validation-provider
                  #default="{ errors }"
                  name="openTimeHour"
                  vid="openTimeHour"
                  rules=""
                >
                  <b-form-input
                    id="register-openTimeHour"
                    v-model="openTime.hour"
                    name="register-openTimeHour"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Hour')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- firstName -->
              <b-form-group
                :label="$t('Minute')"
                label-for="register-openTimeMinute"
              >
                <validation-provider
                  #default="{ errors }"
                  name="openTimeMinute"
                  vid="openTimeMinute"
                  rules=""
                >
                  <b-form-input
                    id="register-openTimeMinute"
                    v-model="openTime.minute"
                    name="register-openTimeMinute"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Minute')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <span>{{ $t('closeTime') }}</span>
            </b-col>
            <b-col cols="6">
              <!-- firstName -->
              <b-form-group
                :label="$t('Hour')"
                label-for="register-closeTimeHour"
              >
                <validation-provider
                  #default="{ errors }"
                  name="closeTimeHour"
                  vid="closeTimeHour"
                  rules=""
                >
                  <b-form-input
                    id="register-closeTimeHour"
                    v-model="closeTime.hour"
                    name="register-closeTimeHour"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Hour')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- firstName -->
              <b-form-group
                :label="$t('Minute')"
                label-for="register-closeTimeMinute"
              >
                <validation-provider
                  #default="{ errors }"
                  name="closeTimeMinute"
                  vid="closeTimeMinute"
                  rules=""
                >
                  <b-form-input
                    id="register-closeTimeMinute"
                    v-model="closeTime.minute"
                    name="register-closeTimeMinute"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Minute')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
        </b-form>
      </validation-observer>
    </template>

    <template #modal-footer>
      <div class="w-100">
        <p class="float-left mb-0" />
        <div class="float-right mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            @click="validationForm()"
          >
            {{
              Object.entries(data).length === 0 ? $t('Add New') : $t('Update')
            }}
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            @click="$emit('update:is-sidebar-active', false)"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal,
  BButton,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BLink,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

import vSelect from 'vue-select'
import store from '@/store'

// eslint-disable-next-line import/extensions
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    BModal,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    day: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataId: null,
      openTime: { hour: 8, minute: 0 },
      closeTime: { hour: 16, minute: 0 },
      limitMember: 1,
      index: -1,
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.initValues()
        } else {
          const {
            _id, openTime, closeTime, limitMember, index,
          } = this.data
          this.dataId = _id
          this.openTime = openTime
          this.closeTime = closeTime
          this.limitMember = limitMember
          this.index = index
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.openTime = { hour: 8, minute: 0 }
      this.closeTime = { hour: 16, minute: 0 }
      this.limitMember = 1
      this.index = -1
      // this.$refs.refInputEl.reset()
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            _id: this.dataId,
            openTime: this.openTime,
            closeTime: this.closeTime,
            day: this.day,
            // eslint-disable-next-line radix
            limitMember: parseInt(this.limitMember),
          }
          console.log(this.index)
          console.log(obj)

          if (this.index > -1) {
            this.$emit('update:is-sidebar-active', false)
            obj.index = this.index
            this.$emit('updateWorkPeriod', obj)
          } else {
            this.$emit('update:is-sidebar-active', false)
            this.$emit('addWorkPeriod', obj)
          }
        }
      })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.btn-remove {
  position: absolute;
  top: 20%;
  left: 30%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}
.imgs-show {
  // width: 100%;
  height: 100px;
}
</style>
